import { type TRPCClient, trpcClientKey } from '../utils/trpc'

export function useTrpc(): TRPCClient {
  const nuxt = useNuxtApp()
  if (hasInjectionContext())
    return inject(trpcClientKey, nuxt.$trpcClient as any)
  return nuxt.$trpcClient as any
}

export const useTRPC = useTrpc
