import { createTrpcClient, trpcClientKey } from '../utils/trpc'

export default defineNuxtPlugin({
  name: 'trpc',
  enforce: 'pre',
  setup({ vueApp }) {
    const config = useRuntimeConfig()
    const token = useCookie('token')

    const trpcClient = createTrpcClient({
      url: config.public.trpcUrl,
      token: token.value,
      isServer: () => import.meta.server ?? false,
    })

    vueApp.provide(trpcClientKey, trpcClient)

    return {
      provide: {
        trpcClient,
      },
    }
  },
})
