import type { RouterInput, RouterOutput, Theme } from '@eliah/trpc'
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns'
import { cloneDeep, isEqual } from 'lodash-es'
import { ofetch } from 'ofetch'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, toRefs } from 'vue'
import { useAppName } from './appName'
import { usePostHog } from './posthog'

export type AuthUser = RouterOutput['auth']['login']['user']
export type AuthItems = Omit<NonNullable<AuthUser['items']>, 'id' | 'userId' | 'createdAt'>
export type AuthPermissions = AuthUser['permissions']
export type AuthStats = AuthUser['stats']

export const FreeTrialStateSubscribed = Symbol('FreeTrialStateSubscribed')
export const FreeTrialStateNoActiveSubscription = Symbol('FreeTrialStateNoActiveSubscription')
export const FreeTrialStateExpired = Symbol('FreeTrialStateExpired')

const DefaultStats: NonNullable<AuthStats> & { userId: number } = {
  userId: -1,
  nbTestsLast30Days: 0,
  nbExercicesLast30Days: 0,
}

const DefaultPermissions: NonNullable<AuthPermissions> & { id: number, userId: number } = {
  id: -1,
  userId: -1,
  maxExercices: 0,
  maxTests: 0,
  maxModuleAccess: 0,
  accessAmourDeSoi: false,
  accessLiberteEtre: false,
  accessSouverainete: false,
  accessUnite: false,
  canCreateBaoExercice: false,
  canCreateTestDiagnostics: false,
  canReadBao: false,
}

export const useAuthStore = defineStore('auth', () => {
  const now = useNow({
    interval: 1000 * 60,
  })
  const route = useRoute()
  const posthog = usePostHog()
  const runtimeConfig = useRuntimeConfig()
  const tokenUrls = computed(() => {
    return runtimeConfig.public.setTokenHosts.split(',').filter(Boolean)
  })

  const appName = useAppName()
  const trpc = useTrpc()

  const data = ref({
    isLoggedIn: false,
    expires: null as string | null,
    user: null as AuthUser | null,
  })

  watch(() => data.value.user, (user) => {
    if (!user)
      return

    posthog?.identify(`user:${user.id}`, {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      first_name: user.firstName,
      last_name: user.lastName,
      role: user.role,
      business_details: user.businessDetails,
      email_confirmed: user.emailConfirmed,
      suspended_account_reason: user.suspendedAccountReason,
      permissions: user.permissions,
    })
  }, {
    immediate: true,
  })

  async function setTokenToRemoteUrls(token: string) {
    const remoteUrls = tokenUrls.value

    const ps = remoteUrls.map(url => ofetch(url, {
      method: 'POST',
      body: {
        token,
      },
      credentials: 'include',
    }))
    try {
      await Promise.all(ps)
    }
    catch (e) {
      console.error('Error setting token to remote urls', e)
    }
  }

  async function deleteTokenFromRemoteUrls() {
    const remoteUrls = tokenUrls.value

    const ps = remoteUrls.map(url => ofetch(url, {
      method: 'DELETE',
      credentials: 'include',
    }))
    try {
      await Promise.all(ps)
    }
    catch (e) {
      console.error('Error deleting token from remote urls', e)
    }
  }

  const login = async (email: string, password: string) => {
    const { expires, user, token } = await trpc.auth.login.mutate({
      email,
      password,
    })

    data.value.isLoggedIn = true
    data.value.expires = expires
    data.value.user = user
    await setTokenToRemoteUrls(token)
  }

  const logout = async () => {
    await trpc.auth.logout.mutate()

    data.value.isLoggedIn = false
    data.value.expires = null
    data.value.user = null
    await deleteTokenFromRemoteUrls()
  }

  const register = async (input: RouterInput['auth']['register']) => {
    const { user, expires, token } = await trpc.auth.register.mutate(input)

    data.value.isLoggedIn = true
    data.value.expires = expires
    data.value.user = user
    await setTokenToRemoteUrls(token)
  }

  const failedEcheanceNiveau = computed(() => {
    const failedEcheances = data.value.user?.payEcheances?.filter(e => e.failedPaymentCount >= 3) ?? []

    return failedEcheances.reduce((acc, e) => {
      const niveauStr = e.description.match(/NIVEAU (\d)/)?.[1]
      const niveau = niveauStr ? Number.parseInt(niveauStr) : 0

      if (!acc || niveau < acc)
        return niveau
      return acc
    }, null as number | null)
  })

  const refreshUser = async () => {
    const oldUser = cloneDeep(data.value.user)
    const oldFailedEcheanceNiveau = failedEcheanceNiveau.value

    try {
      const { user } = await trpc.auth.profile.query()
      data.value.isLoggedIn = true
      data.value.user = user

      // If user has changed, redirect to home to prevent bad permissions access
      const isAllowedRoute = route.path === '/profil'
      const userHasChangedNiveau = oldUser && (!isEqual(oldUser?.items, user.items) || !isEqual(oldFailedEcheanceNiveau, failedEcheanceNiveau.value))
      if (appName === 'platform' && userHasChangedNiveau && !isAllowedRoute)
        await navigateTo('/')
    }
    catch {
      data.value.isLoggedIn = false
      data.value.expires = null
      data.value.user = null
    }
  }

  // Refresh user every 1 minutes
  if (appName === 'platform' && import.meta.client)
    useIntervalFn(refreshUser, 1000 * 60)

  const stats = computed(() => data.value.user?.stats ?? { ...DefaultStats, userId: data.value.user?.id ?? -1 })
  const permissions = computed(() => data.value.user?.permissions ?? { ...DefaultPermissions, userId: data.value.user?.id ?? -1 })
  const echeances = computed(() => data.value.user?.payEcheances ?? [])
  const items = computed<AuthItems>(() => data.value.user?.items ?? {
    freeTrialEndsAt: null,
    subscribed: false,
    niveauExpertise: 'NIVEAU_0',
  } satisfies AuthItems)

  const niveauAccess = computed<Record<'niveau1' | 'niveau2' | 'niveau3', boolean>>(() => {
    const access = {
      niveau1: false,
      niveau2: false,
      niveau3: false,
    }

    for (let i = 0; i < 3; i++) {
      const n = i + 1
      const key = `niveau${n}` as keyof typeof access
      access[key] = permissions.value.maxModuleAccess >= n && !isFailedEcheanceNiveau(n)
    }

    return access
  })

  const niveauExpertiseInt = computed(() => {
    const niveauExpertise = items.value.niveauExpertise
    return Number.parseInt(niveauExpertise.replace('NIVEAU_', ''))
  })

  function isFailedEcheanceNiveau(niveau: number) {
    if (failedEcheanceNiveau.value === null)
      return false
    return niveau >= failedEcheanceNiveau.value
  }

  const adhesionCount = computed(() => data.value.user?.adhesionCount ?? 0)

  const freeTrialEndsAt = computed(() => {
    const freeTrialEndsAt = items.value.freeTrialEndsAt
    return freeTrialEndsAt ? new Date(freeTrialEndsAt) : null
  })

  const isFreeTrial = computed(() => {
    return freeTrialEndsAt.value && freeTrialEndsAt.value > now.value
  })

  const disabledThemes = computed(() => {
    const disabledThemes = new Map<Theme, string>()

    if (!permissions.value.accessAmourDeSoi || isFailedEcheanceNiveau(1))
      disabledThemes.set('AMOUR_DE_SOI', 'Vous n\'avez pas accès à ce thème')

    if (!permissions.value.accessLiberteEtre || isFailedEcheanceNiveau(1))
      disabledThemes.set('LIBERTE_ETRE', 'Vous n\'avez pas accès à ce thème')
    else if (isFreeTrial.value)
      disabledThemes.set('LIBERTE_ETRE', 'Ce thème n\'est pas disponible en période d\'essai')

    if (!permissions.value.accessSouverainete || isFailedEcheanceNiveau(2))
      disabledThemes.set('SOUVERAINETE', 'Vous n\'avez pas accès à ce thème')
    else if (isFreeTrial.value)
      disabledThemes.set('SOUVERAINETE', 'Ce thème n\'est pas disponible en période d\'essai')

    if (!permissions.value.accessUnite || isFailedEcheanceNiveau(3))
      disabledThemes.set('UNITE', 'Vous n\'avez pas accès à ce thème')
    else if (isFreeTrial.value)
      disabledThemes.set('UNITE', 'Ce thème n\'est pas disponible en période d\'essai')

    return disabledThemes
  })

  const fonctionnalitesAccessMap = computed(() => {
    const accessMap: Map<'boite_a_outils' | 'test_diagnostics' | 'formation', string[]> = new Map([
      ['boite_a_outils', []],
      ['test_diagnostics', []],
      ['formation', []],
    ])

    const user = data.value.user
    if (user?.suspendedAccountReason) {
      accessMap.get('formation')!.push('Votre compte est suspendu.')
      accessMap.get('test_diagnostics')!.push('Votre compte est suspendu.')
      accessMap.get('boite_a_outils')!.push('Votre compte est suspendu.')
    }
    else if (failedEcheanceNiveau.value && failedEcheanceNiveau.value === 1) {
      accessMap.get('test_diagnostics')!.push('Votre compte est suspendu.')
      accessMap.get('boite_a_outils')!.push('Votre compte est suspendu.')
      accessMap.get('formation')!.push('Votre compte est suspendu.')
      accessMap.get('formation')!.push('Pour retrouver l’accès aux fonctionnalités de la plateforme, merci de vous rendre dans l’onglet “Mon adhésion” depuis l’espace “ Profil” et de vous assurez que vos coordonnées bancaires sont toujours d’actualité et/ou de les modifier au besoin. Cliquez ensuite sur REPRENDRE LE PAIEMENT EN 3 FOIS')
    }
    else {
      if ((!items.value.subscribed && !isFreeTrial.value) || !permissions.value.canReadBao) {
        if (isFreeTrial.value)
          accessMap.get('boite_a_outils')!.push('Pour avoir accès à la Boîte à outils, merci de finaliser la formation de niveau 1.')
        else
          accessMap.get('boite_a_outils')!.push('Pour avoir accès à la Boîte à outils, merci de finaliser la formation de niveau 1 et de veiller à avoir votre adhésion active.')
      }

      if ((!items.value.subscribed && !isFreeTrial.value) || !permissions.value.canCreateTestDiagnostics) {
        if (isFreeTrial.value)
          accessMap.get('test_diagnostics')!.push('Pour générer des Tests d\'Intériorité®, merci de finaliser la formation de niveau 1.')
        else
          accessMap.get('test_diagnostics')!.push('Pour générer des Tests d\'Intériorité®, merci de finaliser la formation de niveau 1 et de veiller à avoir votre adhésion active.')
      }

      if ((permissions.value.maxModuleAccess ?? 0) < 1) {
        accessMap.get('formation')!.push('Vous n\'avez pas les droits pour accéder à la formation.')
        accessMap.get('formation')!.push('Pour débloquer cette fonctionnalité, veuillez acheter la formation de niveau 1.')
      }
    }

    return accessMap
  })

  const accessBaoBloqueMsg = computed(() => {
    const msgs = fonctionnalitesAccessMap.value.get('boite_a_outils') ?? []
    if (msgs.length === 0)
      return null

    return msgs
  })

  const accessTestDiagnosticsBloqueMsg = computed(() => {
    const msgs = fonctionnalitesAccessMap.value.get('test_diagnostics') ?? []
    if (msgs.length === 0)
      return null

    return msgs
  })

  const accessFormationBloqueMsg = computed(() => {
    const msgs = fonctionnalitesAccessMap.value.get('formation') ?? []
    if (msgs.length === 0)
      return null

    return msgs
  })

  const freeTrialRemaining = computed(() => {
    if (items.value.subscribed)
      return FreeTrialStateSubscribed

    if (!isFreeTrial.value) {
      if (adhesionCount.value > 0)
        return FreeTrialStateNoActiveSubscription

      return FreeTrialStateExpired
    }

    const daysRemaining = differenceInDays(freeTrialEndsAt.value!, now.value)
    const hoursRemaining = differenceInHours(freeTrialEndsAt.value!, now.value) % 24
    const minutesRemaining = differenceInMinutes(freeTrialEndsAt.value!, now.value) % 60

    return {
      daysRemainingDigits: daysRemaining.toString().padStart(2, '0').split(''),
      hoursRemainingDigits: hoursRemaining.toString().padStart(2, '0').split(''),
      minutesRemainingDigits: minutesRemaining.toString().padStart(2, '0').split(''),
    }
  })

  watch(() => data.value.user?.role, async (role) => {
    if (data.value.user && appName === 'backoffice' && role !== 'ADMIN') {
      await logout()
      await navigateTo('/auth/login')
    }
  })

  return {
    ...toRefs(data.value),
    stats,
    permissions,
    register,
    login,
    logout,
    refreshUser,
    niveauAccess,
    items,
    echeances,
    niveauExpertiseInt,
    failedEcheanceNiveau,
    isFailedEcheanceNiveau,
    adhesionCount,
    disabledThemes,
    isFreeTrial,
    freeTrialEndsAt,
    accessBaoBloqueMsg,
    accessTestDiagnosticsBloqueMsg,
    accessFormationBloqueMsg,
    freeTrialRemaining,
  }
})

export const useAuthStoreRefs = () => storeToRefs(useAuthStore())

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
