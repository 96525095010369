import { default as _91slug_93dDbeaX0XUdMeta } from "/vercel/path0/apps/vitrine/src/pages/articles/[slug].vue?macro=true";
import { default as indexHN6SaybFmbMeta } from "/vercel/path0/apps/vitrine/src/pages/articles/index.vue?macro=true";
import { default as formation_45niv_451ycQqTzpY1gMeta } from "/vercel/path0/apps/vitrine/src/pages/checkout/formation-niv-1.vue?macro=true";
import { default as formation_45niv_452kyC0Xcp0osMeta } from "/vercel/path0/apps/vitrine/src/pages/checkout/formation-niv-2.vue?macro=true";
import { default as formation_45niv_453pitDCEiS0QMeta } from "/vercel/path0/apps/vitrine/src/pages/checkout/formation-niv-3.vue?macro=true";
import { default as contact79p9OiJ5A1Meta } from "/vercel/path0/apps/vitrine/src/pages/contact.vue?macro=true";
import { default as faqOWaMNSScSnMeta } from "/vercel/path0/apps/vitrine/src/pages/faq.vue?macro=true";
import { default as accompagnement_45intuitifkDIu3MOJ4zMeta } from "/vercel/path0/apps/vitrine/src/pages/formations/accompagnement-intuitif.vue?macro=true";
import { default as accompagnement_45paix_45interieureWBxuIA1nuKMeta } from "/vercel/path0/apps/vitrine/src/pages/formations/accompagnement-paix-interieure.vue?macro=true";
import { default as roue_45ascensionsP3ns6vwBjMeta } from "/vercel/path0/apps/vitrine/src/pages/formations/roue-ascension.vue?macro=true";
import { default as indexrVdf5EGdNEMeta } from "/vercel/path0/apps/vitrine/src/pages/index.vue?macro=true";
import { default as nos_45offresm3Sh5Ok6PZMeta } from "/vercel/path0/apps/vitrine/src/pages/nos-offres.vue?macro=true";
import { default as plateforme_45accompagnementS5ONpm8NHCMeta } from "/vercel/path0/apps/vitrine/src/pages/plateforme-accompagnement.vue?macro=true";
import { default as portailia9JC0rOvbMeta } from "/vercel/path0/apps/vitrine/src/pages/portail.vue?macro=true";
import { default as qui_45sommes_45nousXVPkjDQEpwMeta } from "/vercel/path0/apps/vitrine/src/pages/qui-sommes-nous.vue?macro=true";
import { default as temoignagesy0DvJpmG9VMeta } from "/vercel/path0/apps/vitrine/src/pages/temoignages.vue?macro=true";
export default [
  {
    name: "articles-slug",
    path: "/articles/:slug()",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/articles/[slug].vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/articles/index.vue")
  },
  {
    name: "checkout-formation-niv-1",
    path: "/checkout/formation-niv-1",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/checkout/formation-niv-1.vue")
  },
  {
    name: "checkout-formation-niv-2",
    path: "/checkout/formation-niv-2",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/checkout/formation-niv-2.vue")
  },
  {
    name: "checkout-formation-niv-3",
    path: "/checkout/formation-niv-3",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/checkout/formation-niv-3.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/contact.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/faq.vue")
  },
  {
    name: "formations-accompagnement-intuitif",
    path: "/formations/accompagnement-intuitif",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/formations/accompagnement-intuitif.vue")
  },
  {
    name: "formations-accompagnement-paix-interieure",
    path: "/formations/accompagnement-paix-interieure",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/formations/accompagnement-paix-interieure.vue")
  },
  {
    name: "formations-roue-ascension",
    path: "/formations/roue-ascension",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/formations/roue-ascension.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/index.vue")
  },
  {
    name: "nos-offres",
    path: "/nos-offres",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/nos-offres.vue")
  },
  {
    name: "plateforme-accompagnement",
    path: "/plateforme-accompagnement",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/plateforme-accompagnement.vue")
  },
  {
    name: "portail",
    path: "/portail",
    meta: portailia9JC0rOvbMeta || {},
    component: () => import("/vercel/path0/apps/vitrine/src/pages/portail.vue")
  },
  {
    name: "qui-sommes-nous",
    path: "/qui-sommes-nous",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/qui-sommes-nous.vue")
  },
  {
    name: "temoignages",
    path: "/temoignages",
    component: () => import("/vercel/path0/apps/vitrine/src/pages/temoignages.vue")
  }
]