<script setup lang="ts">
useHead({
  titleTemplate: title => title ? `${title} - Eliah` : 'Eliah',
})

useSchemaOrg([
  defineOrganization({
    name: 'Eliah Institut',
    logo: '/img/eliah-logo-hjt.png',
    url: 'https://www.eliah-institut.fr',
    sameAs: [
      'https://www.linkedin.com/company/eliah-institut',
      'https://www.instagram.com/eliah_institut',
    ],
  }),
  defineWebSite({
    url: 'https://www.eliah-institut.fr',
    name: 'Eliah Institut',
    description: 'Bienvenue sur le site d\'Eliah',
    inLanguage: ['fr-FR'],
  }),
  defineWebPage({
    description: 'Bienvenue sur le site d\'Eliah',
  }),
])
</script>

<template>
  <div class="bg-void">
    <LayerInject />
    <NuxtLayout class="font-skia">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
