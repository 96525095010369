import type { AppRouter } from '@eliah/trpc'
import type { CreateTRPCProxyClient } from '@trpc/client'
import type { InjectionKey } from 'vue'
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'

export type TRPCClient = CreateTRPCProxyClient<AppRouter>

interface TrpcClient {
  url: string
  token?: string | null | undefined
  isServer?: () => boolean
}

export function createTrpcClient({ url, token, isServer }: TrpcClient): TRPCClient {
  return createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url: !isServer?.() ? '/api/trpc' : url,
        headers: () => {
          if (isServer?.()) {
            if (token) {
              return {
                Authorization: `Bearer ${token}`,
              }
            }
          }
          return {}
        },
      }),
    ],
  })
}

export const trpcClientKey: InjectionKey<TRPCClient> = Symbol('trpcClient')
