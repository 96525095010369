import type { PostHog } from 'posthog-js'
import { once } from 'lodash-es'

const logPosthogMissing = once(() => {
  console.warn('PostHog is not available.')
})

export function usePostHog(): PostHog | undefined {
  const { $clientPosthog, $serverPosthog } = useNuxtApp()

  if (!$clientPosthog && !$serverPosthog) {
    logPosthogMissing()
    return
  }

  if (import.meta.client)
    return ($clientPosthog ?? undefined) as PostHog | undefined

  if (import.meta.server)
    return ($serverPosthog ?? undefined) as PostHog | undefined
}
