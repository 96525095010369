import payload_plugin_EIFeaCx54E from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_VLBBpGNbEM from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5Aqhpgqwip from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cl5uZvSp4Y from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_kK2dYfNlro from "/vercel/path0/node_modules/.pnpm/nuxt-posthog@1.6.1_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import _0_siteConfig_j4Gi9ACjGh from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.28.1_vite@6.0.3_@types+node@22.10.2_jiti@2.4.1_6z5a2ln6zl26mnaihifx7pwjwa/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import trpc_znYpS9RtZP from "/vercel/path0/packages/nuxt-layer/src/plugins/trpc.ts";
import payload_client_9F4sqLoOUj from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_NoFoqP8ZIP from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_prAsdDudBA from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vFoIKMBUa8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UqPPVgj4Bm from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.28.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/vitrine/.nuxt/components.plugin.mjs";
import prefetch_client_fBgZXiSs9s from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.17.0_jiti@2.4.1__ioredis@5._eodusikeoiskahtnrvb2m6qff4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_0vFpYwthpk from "/vercel/path0/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.28.1/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_ziYcKwnJdq from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import directives_2WP5dcrMaC from "/vercel/path0/node_modules/.pnpm/nuxt-posthog@1.6.1_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import siteConfig_y3avd5rJ4q from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.4_magicast@0.3.5_rollup@4.28.1_vite@6.0.3_@types+node@22.10.2_jiti@2.4.1_s_r5y6oy3grujwhnczqv6ymy22cu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_49JVM6S3O6 from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.4_magicast@0.3.5_rollup@4.28.1_vite@6.0.3_@types+node@22.10.2_jiti@2.4.1_s_r5y6oy3grujwhnczqv6ymy22cu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_uF6mtu5oVv from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.4_magicast@0.3.5_rollup@4.28.1_vite@6.0.3_@types+node@22.10.2_jiti@2.4.1_s_r5y6oy3grujwhnczqv6ymy22cu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/vitrine/.nuxt/unocss.mjs";
import nuxt_layer_appNameProvider_Lr3LRlcpWa from "/vercel/path0/apps/vitrine/.nuxt/nuxt-layer-appNameProvider.mjs";
import toast_client_GNy1Fec2fD from "/vercel/path0/packages/nuxt-layer/src/plugins/toast.client.ts";
import user_client_x0cRPl1LQz from "/vercel/path0/packages/nuxt-layer/src/plugins/user.client.ts";
import defaults_IEE3vaHEqK from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.4_magicast@0.3.5_rollup@4.28.1_vite@6.0.3_@types+node@22.10.2_jiti@2.4.1_s_r5y6oy3grujwhnczqv6ymy22cu/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_EIFeaCx54E,
  revive_payload_client_VLBBpGNbEM,
  unhead_5Aqhpgqwip,
  router_cl5uZvSp4Y,
  posthog_client_kK2dYfNlro,
  _0_siteConfig_j4Gi9ACjGh,
  trpc_znYpS9RtZP,
  payload_client_9F4sqLoOUj,
  navigation_repaint_client_NoFoqP8ZIP,
  check_outdated_build_client_prAsdDudBA,
  chunk_reload_client_vFoIKMBUa8,
  plugin_vue3_UqPPVgj4Bm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fBgZXiSs9s,
  preview_client_0vFpYwthpk,
  plugin_ziYcKwnJdq,
  directives_2WP5dcrMaC,
  siteConfig_y3avd5rJ4q,
  inferSeoMetaPlugin_49JVM6S3O6,
  titles_uF6mtu5oVv,
  unocss_MzCDxu9LMj,
  nuxt_layer_appNameProvider_Lr3LRlcpWa,
  toast_client_GNy1Fec2fD,
  user_client_x0cRPl1LQz,
  defaults_IEE3vaHEqK
]