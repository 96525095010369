import type { PluginOptions } from 'vue-toastification'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(Toast, {
    newestOnTop: true,
    maxToasts: 3,
    position: POSITION.BOTTOM_RIGHT,
  } satisfies PluginOptions)
})
